import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/apps/blog/src/components/PageLayout/PageLayout.tsx";
import { Avatar, Container, PageOffset, Typography } from 'components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container marginTop="3rem" mdxType="Container">
    <Avatar real borderSize="0rem" mdxType="Avatar" />
    </Container>
    <Container mdxType="Container">
    <Typography variant="h1" component="h1" enableVerticalGutter mdxType="Typography">
        Hello there
    </Typography>
    </Container>
    <p>{`My name is Sergei. Yeh, that photo above is how I actually look in real life.`}</p>
    <p>{`I am a `}<strong parentName="p">{`full-stack software engineer in web domain`}</strong>{`. I walk both worlds: front-end and back-end. I enjoy writing efficient, clean code of high standard, as well as making crappy prototypes to validate ideas.`}</p>
    <p>{`Being passionate about technology, I have good relationships with React, TypeScript and Golang. I am a fierce evangelist of infrastructure-as-code and a cloud native application practitioner. However, I also keep myself exposed to `}<a parentName="p" {...{
        "href": "/skills"
      }}>{`other related technologies`}</a>{`.`}</p>
    <p>{`I believe that healthy teamwork, mutual respect along with strong focus on company's interests is the key to achieving success.`}</p>
    <p>{`Sometimes I `}<a parentName="p" {...{
        "href": "/blog"
      }}>{`publish articles`}</a>{` on what I recently learned.`}</p>
    <p>{`I like `}<a parentName="p" {...{
        "href": "https://www.instagram.com/gannochenko_arch/"
      }}>{`good architecture`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/im_a_creator_of_worlds/"
      }}>{`3d visualization`}</a>{`, Arduino, AI, good drama and horror movies, science fiction. I enjoy graphically well-made anime, such as by Ghibli. I am also interested in politics (yes, don't look at me like that).`}</p>
    <p>{`I don't like pets, sorry, for I have vast and mostly unpleasant background. I adore children though. I am a happy father of a little cute boy, awwww.`}</p>
    <p>{`I am currently based in Berlin, Germany. You can always `}<a parentName="p" {...{
        "href": "/contacts"
      }}>{`drop me a message`}</a>{`.`}</p>
    <PageOffset mdxType="PageOffset" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      